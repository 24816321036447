import React from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import { Themed } from '@theme-ui/mdx'
import {
  TableExtraMessage,
  TableWrapper,
  TableWrapperCell,
  TableWrapperCellHeader,
  TableWrapperRow,
} from './styles'

type Props = {
  data: Array<{
    firstCol: any
    secondCol: any
  }>
  tableSxProps?: ThemeUIStyleObject
  extraMessage?: string
  textColor?: 'light' | 'dark'
}

const CONTACT_METHODS_LINKS = {
  email: 'mailto:',
  phone: 'tel:',
}

export const TableV2 = ({ data, extraMessage, textColor }: Props) => {
  return (
    <TableWrapper $textColor={textColor}>
      {data.map((row, index) => {
        const typedRow =
          CONTACT_METHODS_LINKS[row.firstCol.toLowerCase() as keyof typeof CONTACT_METHODS_LINKS]
        return (
          <TableWrapperRow key={index}>
            <TableWrapperCellHeader>{row.firstCol}</TableWrapperCellHeader>
            <TableWrapperCell>
              {typedRow ? (
                <Themed.a
                  href={`${typedRow}${row.secondCol.trim().toLowerCase()}`}
                  sx={{
                    textTransform: 'uppercase',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                >
                  {row.secondCol}
                </Themed.a>
              ) : (
                row.secondCol
              )}
            </TableWrapperCell>
          </TableWrapperRow>
        )
      })}
      {extraMessage && (
        <TableExtraMessage>
          <TableWrapperCell>{extraMessage}</TableWrapperCell>
        </TableExtraMessage>
      )}
    </TableWrapper>
  )
}

export default TableV2
