/** @jsx jsx */
// @ts-ignore
import { Container, Text, Box, jsx } from 'theme-ui'
import { FC, Fragment } from 'react'
import Layout from '~/components/layout'
import HeroImage from '~/components/hero-image'
import MarkdownIt from '~/components/MarkdownIt'
import { GatsbyImageType } from '~/@types/models'
import ProductCarousel from '~/components/products-carousel'
import SliderArrowCustom from '~/components/products-carousel/chevron-arrow'
import HomepageHeader from '~/components/homepage/header'
import HeadingV2 from '~/componentsV2/heading'
import { OpeningsV2 } from '~/componentsV2/openings'
import { ShowroomWrapper } from '~/componentsV2/markDownPages/showroons'

export type NycShowroomProps = {
  data: {
    frontmatter: {
      header: {
        title: string
        description: string
        image: GatsbyImageType
        imageMobile?: GatsbyImageType
      }
      showroomTitle: string
      headerQuote: string
      showroomLink: string
      address: {
        line1: string
        line2: string
      }
      openHours: Array<{
        day: string
        time: string
      }>
      carousel: ReadonlyArray<{
        image: GatsbyImageType
        imageMobile: GatsbyImageType
      }>
    }
    html: string
  }
}

const NycShowroom: FC<NycShowroomProps> = ({ data }) => {
  const {
    html: content,
    frontmatter: { header, carousel, headerQuote, address, openHours, showroomTitle, showroomLink },
  } = data

  const HeaderInfos = ({ textColor }: { textColor: 'light' | 'dark' }) => (
    <Fragment>
      <HeadingV2
        className="product-detail-panel__title"
        sx={{
          textAlign: 'initial',
          color: ['#000', null, '#fff'],
          fontSize: ['26px !important', null, '32px !important'],
          textTransform: 'uppercase',
          fontWeight: 400,
          paddingRight: 0,
          marginBottom: [10, 20, 40],
        }}
      >
        {header?.title}
      </HeadingV2>
      <Box>
        <OpeningsV2
          textColor={textColor}
          openHours={openHours}
          title={showroomTitle}
          address={address}
        />
      </Box>
      <HeadingV2
        className="product-detail-panel__title"
        sx={{
          color: ['#000', null, '#fff'],
          fontSize: '24px !important',
          textTransform: 'uppercase',
          fontWeight: 400,
          paddingRight: 0,
          marginTop: [10, 20, 40],
          textAlign: ['initial', null, 'center'],
        }}
      >
        Courtesy <i style={{ textTransform: 'lowercase' }}>to the</i> trade
      </HeadingV2>
    </Fragment>
  )

  return (
    <Layout topBanner={false} title={header.title} description={header.description}>
      <HomepageHeader
        title={
          <Box
            sx={{
              display: ['none', null, 'block'],
            }}
          >
            <HeaderInfos textColor="light" />
          </Box>
        }
        infoContainerSx={{
          width: [300, 750, 900, 1250],
        }}
        heroSx={{
          justifyContent: 'center !important',
          alignItems: 'center',
        }}
        titleProps={{
          fontSize: '32px !important',
          textTransform: 'uppercase',
          fontWeight: '400 !important',
          paddingRight: 0,
          marginBottom: 0,
        }}
        images={{
          default: header.image,
          mobile: header.imageMobile,
        }}
        contentPosition="left"
      />
      {/* <Container
        variant="containerPrimary"
        sx={{
          my: [50, null, 100],
        }}
      >
        <Text
          sx={{
            display: ['block', null, 'none'],
            mb: 'md1',
          }}
          variant="regular"
        >
          {header.description}
        </Text>
        <Box mb={['l', null, 'xl']}>
          <Text as="blockquote" variant="regular">
            &ldquo;{headerQuote}&ldquo; - Ben Soleimani
          </Text>
        </Box>
      </Container> */}
      <Container variant="containerPrimary">
        <Box
          sx={{
            display: ['block', null, 'none'],
            pt: 20,
          }}
        >
          <HeaderInfos textColor="dark" />
        </Box>
        <Box my={['l', null, 'xl']}>
          <ShowroomWrapper>
            <MarkdownIt content={content} />
          </ShowroomWrapper>
          <Text
            sx={{
              display: 'block',
              fontSize: 18,
            }}
          >
            <a
              href={showroomLink}
              target="_blank"
              rel="noreferrer"
              style={{
                display: 'block',
                color: 'inherit',
                textDecoration: 'underline',
                marginTop: 20,
                fontSize: 18,
              }}
            >
              SHOP THE VIRTUAL SHOWROOM
            </a>
          </Text>
        </Box>
      </Container>
      <ProductCarousel
        variant="primary"
        settings={{
          dots: false,
          arrows: true,
          nextArrow: <SliderArrowCustom position="right" />,
          prevArrow: <SliderArrowCustom />,
        }}
        data={carousel.map(item => {
          return () => (
            <HeroImage
              images={{
                default: item.image,
                mobile: item.imageMobile,
              }}
              height="auto"
            />
          )
        })}
      />
    </Layout>
  )
}

export default NycShowroom
