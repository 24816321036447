import styled from 'styled-components'

export const ShowroomWrapper = styled.div`
  display: contents;

  h2 {
    color: #0d0d0d;
    font-family: 'Cormorant Garamond';
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 54px;
    text-transform: uppercase;

    @media (max-width: 768px) {
      display: none;
    }
  }

  p {
    color: inherit;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    margin-bottom: 40px;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 29px;
      margin-bottom: 20px;
    }
  }
`
