import styled, { css } from 'styled-components'

const cellCss = css`
  text-align: left;

  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  line-height: 34px;
`

export const TableWrapper = styled.div<{ $textColor?: 'light' | 'dark' }>`
  max-width: 375px;
  color: ${({ $textColor }) => ($textColor === 'light' ? '#fff' : '#0d0d0d')};

  a {
    color: ${({ $textColor }) => ($textColor === 'light' ? '#fff' : '#0d0d0d')};
    text-decoration: none;
  }
`

export const TableWrapperRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

export const TableWrapperCell = styled.div`
  ${cellCss}
  font-weight: 400;
`

export const TableWrapperCellHeader = styled.div`
  ${cellCss}
  font-weight: 700;
`
export const TableExtraMessage = styled.div`
  margin-top: 45px;

  @media (max-width: 768px) {
    margin-top: 25px;
  }
`
