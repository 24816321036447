// @ts-ignore
import { Box, Heading, Text } from 'theme-ui'
import React, { FC } from 'react'
import Dash from '~/components/dash'
import Table from '~/components/table'
import TableV2 from '../table'

type Props = {
  title: string
  address?: {
    line1: string
    line2: string
  }
  openHours: Array<{
    day: string
    time: string
  }>
  addressInTable?: boolean
  textColor: 'light' | 'dark'
}

export const OpeningsV2: FC<Props> = ({
  openHours,
  address,
  addressInTable = false,
  textColor,
  ...rest
}) => {
  let openHoursNew = openHours.map(item => ({
    firstCol: item.day,
    secondCol: item.time,
  }))

  if (addressInTable && address) {
    openHoursNew = [
      {
        firstCol: 'Address',
        secondCol: `${address.line1}, ${address.line2}`,
      },
      ...openHoursNew,
    ]
  }

  return (
    <Box {...rest}>
      <TableV2
        textColor={textColor}
        data={openHoursNew}
        extraMessage={address && !addressInTable ? `${address.line1}, ${address.line2}` : ''}
      />
    </Box>
  )
}
